import React, { Component } from 'react'
import mobileVideo from "../images/video.mp4"
import frame from "../images/frame.png"
import screen from "../images/screen.png"

class VideoPlayer extends Component {
    // constructor to set state and bind "this"

    static defaultProps = {
        initialMuted: true,
    }
    
    state = {
        muted: Boolean(this.props.initialMuted),
    }

  
    // function to handle the click
    handleClick = () => {
        this.setState(state => {
          return {
            muted: !state.muted,
          }
        })
    }
     
    handleMouseOut = () => {
        this.setState(state => {
          return {
            value: true,
          }
        })
      }
    
    // the render() method to put stuff into the DOM
    render() {
      console.log("Re-render")
      // the return() to put your default HTML into the DOM
      return (
        <>
        <img src={frame} style={{width: "314px", height: "659.5px", position: 'absolute', pointerEvents: "none",}} alt="" />
        <video 
          playsinline
          autoPlay
          loop
          muted={this.state.muted}
          poster={screen}
          style={{marginTop: '14.5px', marginLeft:'7.5px'}} 
          width="300px" 
          height="630px"
          onClick={this.handleClick} >
          <source src={mobileVideo} type="video/mp4" />
          <p>Your browser doesn't support HTML5 video. Here is a <a href={mobileVideo}>link to the video</a> instead.</p>
        </video>
        </>
      );
    }
  }

export default VideoPlayer
