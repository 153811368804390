import React from 'react'
import { Link } from 'gatsby'

import VideoPlayer from '../components/videoPlayer'

import Layout from '../components/layout'
import appStore from "../images/app-store.png"

const IndexPage = ({ muted }) => (
  <Layout>
    <main>
      <section>
        <VideoPlayer />
      </section>
      <section>
        <h1 style={{
          color: '#FFFFFF',
          fontFamily: 'wickedMouse',
          fontSize: '28px',
          letterSpacing: '2px',
          textShadow: '3px 3px rgba(0, 0, 0, 0.4)',
          textAlign: 'center',
          textDecoration: 'none',
          lineHeight: '50px'
        }}>A pirate themed scavenger hunt app for kids.</h1>
        <div style={{
            margin: '0 auto', 
            width: '201px', 
            textAlign: 'center',
          }}><a  href="https://itunes.apple.com/us/app/treasure-hunt-scavenger-hunt/id1426797007?ls=1&mt=8">
            <img src={appStore} alt="Link to app store"/>
        </a></div>
        <div style={{
            margin: '0 auto', 
            width: '201px', 
            textAlign: 'center',
          }}><a href="mailto:support@arrrg.app" style={{
            color: '#222222', 
            fontSize: '17px',
            textAlign: 'center',
            margin: '0 auto', 
            width: '250px',
        }}>Need Help?</a></div>
        <div style={{
            margin: '0 auto', 
            width: '201px', 
            textAlign: 'center',
          }}><Link to="/privacy-policy/" style={{
            color: '#222222', 
            fontSize: '14px',
            textAlign: 'center',
            margin: '0 auto', 
            width: '250px',
        }}>Privacy Policy</Link></div>
      </section>
    </main>
  </Layout>
)

export default IndexPage
